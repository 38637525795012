/* Transparency.module.css */
/* Tela principal */
.transparency_background {
    color: var(--text-color);
    background-image: var(--background-image);
    background-size: var(--background-size);
    background-position: var(--background-position);
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: var(--min-height);
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    padding: 1rem;
  }
  
  /* Caminho de navegação */
  .path_border {
    width: 100%;
    border-bottom: 1px solid #5b95f9;
    margin-bottom: 1rem;
  }
  
  .path {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    gap: 0.5rem;
    margin: 0 auto;
    padding: 0.5rem 1rem;
    max-width: 1200px;
  }
  
  .path a {
    color: #2f64bf;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  
  .path a:hover {
    color: #1b3c74;
  }
  
  .path span {
    color: #5b95f9;
  }
  
  /* Container da transparência */
  .transparency_container {
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: var(--margin);
    padding: var(--padding);
    min-width: var(--min-width);
    max-width: var(--max-width);
    flex-grow: var(--flex-grow);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.8s ease-in-out;
    border-radius: 16px;
  }
  
  /* Título */
  .title {
    font-size: 2.6rem;
    text-align: center;
    margin-bottom: 1rem;
    font-weight: bold;
    color: var(--text-color);
  }
  
  /* Container da lista */
  .cont_list_container {
    justify-content: center;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: var(--container-background-color); /* Substituído aqui */
    padding: 1rem;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease;
  }
  
  
  /* Botão personalizado (caso usado futuramente) */
  .custom_button {
    background-color: #ffffff;
    color: black;
    padding: 10px 50px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s, opacity 0.3s;
    min-width: 80%;
  }
  
  .custom_button:hover {
    opacity: 0.85;
    transform: scale(1.02);
  }
  
  /* Animação */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsivo */
  @media (max-width: 768px) {
    .title {
      font-size: 2rem;
    }
  
    .path {
      font-size: 1rem;
      padding: 0.5rem;
    }
  
    .cont_list_container {
      padding: 0.5rem;
    }
  }
  