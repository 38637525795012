/* Navbar.module.css */

.navbar {
    background-color: var(--navbar-background-color);
    border-bottom: 2px solid #5b95f9;
    padding: 0.75rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
  }
  
  .list {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
    max-width: 1200px;
    justify-content: space-between;
  }
  
  /* LOGO */
  .logo {
    max-height: 90px;
    display: flex;
    align-items: center;
  }
  
  /* LINKS */
  .item {
    background-color: var(--navbar-item-background-color);
    border-radius: 8px;
    padding: 0.5rem 1rem;
    transition: background 0.3s ease, color 0.3s ease;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  }
  
  .item a {
    color: var(--navbar-font-color);
    text-decoration: none;
    font-weight: 500;
    font-size: 1.4rem;
  }
  
  .item:hover {
    background-color: #8ca9de;
  }
  
  .item a:hover {
    color: black;
  }
  
  /* LOGIN */
  .login_button {
    background-color: var(--navbar-login-background-color);
    border-radius: 8px;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    transition: background 0.3s ease;
    gap: 0.5rem;
  }
  
  .login_button a {
    color: var(--navbar-login-font-color);
    text-decoration: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
  }
  
  .login_button:hover {
    background-color: #8ca9de;
  }
  
  .login_button a:hover {
    color: black;
  }
  
  /* TEMA */
  .theme_button {
    margin-left: auto;
  }
  
  /* RESPONSIVO */
  @media (max-width: 768px) {
    .list {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
    }
  
    .item,
    .login_button {
      width: 100%;
      justify-content: center;
    }
  
    .navbar {
      padding: 1rem;
    }
  
    .theme_button {
      margin-left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  