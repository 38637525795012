.login_background {
    color: var(--text-color);
    background-image: var(--background-image);
    background-size: var(--background-size);
    background-position: var(--background-position);
    display: var(--display);
    flex-direction: var(--flex-direction);
    align-items: var(--align-items);
    min-height: var(--min-height);
}

.login_container {
    background-color: var(--background-color);
    display: var(--display);
    flex-direction: var(--flex-direction);
    align-items: var(--align-items);
    justify-content: var(--justify-content);
    margin: var(--margin);
    padding: var(--padding);
    min-width: var(--min-width);
    max-width: var(--max-width);
    flex-grow: var(--flex-grow);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.8s ease-in-out;
}

.login_container h1 {
    font-size: 2.5em;
    margin-bottom: 1em;
    cursor: default;
}

.login_container h1 span {
    color: rgb(35, 131, 227);
    padding: 0.2em;
    background-color: #282c34;
}

.login_container p {
    margin-bottom: 0.5em;
}

.login_container img {
    width: 350px;
    margin: 2em 0;
}

.login_form_container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
  
.input_container {
    margin-bottom: 15px;
    margin-right: 15px;
}
  
label {
    margin-bottom: 5px;
}
  
input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
  
.button {
    background-color: #4caf50;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
  
.button:hover {
    background-color: #45a049;
}

.path {
    display: flex;
    margin-left: 15%;
    list-style: none;
    font-size: calc(2px + 2vmin);
}

.path span{
    margin-left: 10px;
    color: #5b95f9;
}

.path a {
    cursor: pointer;
    color: #2f64bf;
    text-decoration: none;
}

.path a:hover {
    color: #1b3c74;
    text-decoration: none;
}

.path_border {
    border-bottom: solid 1px #5b95f9;
}

/* Animação */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}