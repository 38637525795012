.card {
  width: 175px; /* largura do cartão */
  height: 310px; /* altura do cartão */
  border: 1px solid #ccc; /* borda sólida de 1px em cinza claro */
  border-radius: 10px; /* bordas arredondadas */
  background-color: #f0f0f0; /* cor de fundo do cartão */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* sombra suave */
  padding: 5px; /* espaçamento interno */
  margin: 7.5px 0; /* margem externa (vertical) */
  display: inline-block; /* torna os cartões em linha */
  vertical-align: top; /* alinha os cartões ao topo */
  text-align: center;
}

.card h3{
  color: #5b95f9;
  margin-bottom: 5px;
  margin-top: 10px;
}

.card p{
  color: #5b95f9;
  margin-top: 10px;
  margin-bottom: 5px;
}

.card img {
  width: 100%;
  border-radius: 100px; /* Adiciona um raio de 10 pixels à borda da imagem */
  border: 2px solid #000; /* Defina estilo e cor da borda conforme desejado */
  margin-bottom: 8px;
}

.card:hover {
  background-color: #bfd4fb;
}

/* Ajustes para alinhar os cartões lado a lado */
@media (min-width: 768px) {
  .card {
    margin: 7.5px; /* margem externa (horizontal) */
  }

  .card:first-child {
    margin-left: 0; /* Remove a margem à esquerda do primeiro cartão */
  }

  .card:last-child {
    margin-right: 0; /* Remove a margem à direita do último cartão */
  }
}