/* CardBoard.module.css */
.card-board {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 50%;
    max-width: 95%;
}
  
.card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    width: 200px;
    text-align: center;
}