/* Home.module.css */

/* Estilização Geral */
.home_background {
    color: var(--text-color);
    background-image: var(--background-image);
    background-size: var(--background-size);
    background-position: var(--background-position);
    display: var(--display);
    flex-direction: var(--flex-direction);
    align-items: var(--align-items);
    min-height: var(--min-height);
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  /* Container Principal */
  .home_container {
    background-color: var(--background-color);
    display: var(--display);
    flex-direction: var(--flex-direction);
    align-items: var(--align-items);
    justify-content: var(--justify-content);
    margin: var(--margin);
    padding: var(--padding);
    min-width: var(--min-width);
    max-width: var(--max-width);
    flex-grow: var(--flex-grow);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    animation: fadeIn 0.8s ease-in-out;
  }
  
  /* Título Principal */
  .title {
    font-size: 2.8rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0.5em;
    color: var(--text-color);
  }
  
  /* Subtítulo */
  .subtitle {
    font-size: 1.25rem;
    text-align: center;
    margin-bottom: 2rem;
    color: var(--text-color);
    opacity: 0.9;
  }
  
  /* Seções */
  .about_section,
  .objectives_section,
  .video_section {
    width: 100%;
    text-align: center;
    margin-bottom: 2.5rem;
    padding: 0 1rem;
  }
  
  /* Títulos das Seções */
  .section_title {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 1.2rem;
    color: var(--text-color);
    border-bottom: 3px solid var(--text-color);
    padding-bottom: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
  
  /* Parágrafos */
  .section_text {
    font-size: 1.1rem;
    line-height: 1.6;
    text-align: justify;
    max-width: 90%;
    margin: 0 auto 1.5rem;
    color: var(--text-color);
    opacity: 0.95;
  }
  
  /* Lista de Objetivos */
  .objectives_list {
    list-style: none;
    padding: 0;
    text-align: left;
    max-width: 85%;
    margin: 2rem auto;
  }
  
  .objectives_list li {
    font-size: 1.1rem;
    margin-bottom: 1.2rem;
    padding: 1rem;
    background: var(--list-background);
    border-left: 4px solid var(--text-color);
    border-radius: 8px;
    display: flex;
    gap: 0.75rem;
    align-items: flex-start;
    transition: transform 0.2s ease-in-out, background 0.3s ease-in-out;
  }
  
  .objectives_list li:hover {
    transform: scale(1.02);
    background: var(--list-hover-background);
  }
  
  /* Ícones da lista */
  .objectives_list li svg {
    min-width: 24px;
    min-height: 24px;
    flex-shrink: 0;
    color: var(--text-color);
  }
  
  /* Vídeo */
  .video_container {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
  }
  
  .video {
    width: 90%;
    max-width: 800px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  /* Animação */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .title {
      font-size: 2.2rem;
    }
  
    .section_title {
      font-size: 1.5rem;
      flex-direction: column;
      text-align: center;
    }
  
    .objectives_list li {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  