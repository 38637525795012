/* TransparencyExpansiveList.module.css */
/* Container geral da lista */
.expansive_layout {
    flex-grow: 1;
    list-style: none;
    padding: 0;
    margin-top: 1rem;
    animation: fadeIn 0.4s ease-in-out;
  }
  
  /* Botões */
  .buttonClass {
    margin: 0.5rem;
    color: #fff;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    font-size: 1rem;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .button_layout {
    background-color: #5b95f9;
  }
  
  .button_layout:hover {
    background-color: #8ca9de;
    transform: scale(1.03);
  }
  
  .selected_button {
    background-color: #295aae;
  }
  
  .selected_button:hover {
    background-color: #1f4ca0;
    transform: scale(1.02);
  }
  
  /* Títulos */
  .title_docs {
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--text-color);
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  /* Centralização de roletas */
  .centerroulette {
    max-width: 100%;
    text-align: center;
    margin: 1rem auto;
  }
  
  /* Hover em nome de documento */
  .namedocs {
    opacity: 1;
    transition: opacity 0.3s ease;
  }
  
  .namedocs:hover {
    opacity: 0.6;
  }
  
  /* Caso use botão extra */
  .custom_button {
    background-color: #ffffff;
    color: black;
    padding: 10px 50px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s, opacity 0.3s;
    min-width: 80%;
  }
  
  .custom_button:hover {
    opacity: 0.85;
    transform: scale(1.02);
  }
  
  /* Animação */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsivo */
  @media (max-width: 768px) {
    .buttonClass {
      width: 90%;
      font-size: 1rem;
      padding: 0.75rem 1rem;
    }
  
    .title_docs {
      font-size: 1.4rem;
    }
  
    .centerroulette {
      padding: 0 0.5rem;
    }
  }
  
  .tooltip {
    display: none;
    position: absolute;
    top: 110%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: white;
    padding: 4px 8px;
    border-radius: 6px;
    font-size: 0.8rem;
    white-space: nowrap;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    z-index: 10;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .buttonClass {
    position: relative;
  }
  
  .buttonClass:hover .tooltip {
    display: block;
    opacity: 1;
  }
  