/* RouletteBoard.module.css */
/* CSS Roulette */
.roulette {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 90%;
  max-width: 100%;
  margin: 0 auto;
}

.buttonLeft,
.buttonRight {
  width: 40px;
  height: 40px;
  color: #fff;
  background-color: #5b95f9;
  border: 2px solid #000;
  border-radius: 50%;
  font-size: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: 1px;
  padding-right: 1px;
  padding-bottom: 1px;
  padding-left: 5px;
}

.buttonLeft:hover,
.buttonRight:hover {
  cursor: pointer;
}

.card-board {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 50%;
  max-width: 95%;
}

.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  width: 200px;
  text-align: center;
}

/* Ajustes para alinhar os cartões lado a lado */
@media (min-width: 768px) {
  .card {
    margin: 7.5px 0; /* margem externa (vertical) */
    display: inline-block; /* torna os cartões em linha */
    vertical-align: top; /* alinha os cartões ao topo */
  }

  .card:first-child {
    margin-left: 0; /* Remove a margem à esquerda do primeiro cartão */
  }

  .card:last-child {
    margin-right: 0; /* Remove a margem à direita do último cartão */
  }
}

/* Roulette.module.css */

/* Estilo para o cargo */
.cargo {
  font-weight: bold; /* Altere o estilo do texto do cargo conforme desejado */
}

/* Estilo para a função */
.funcao {
  font-size: 1.17em; /* Modifique o tamanho da fonte conforme desejado */
  margin: 0; /* Remova as margens padrão para personalizar conforme necessário */
  font-weight: bold; /* Mantenha o peso da fonte caso seja desejado */
}