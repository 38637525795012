.item {
  margin: 0.5rem auto;
  width: 100%;
  max-width: 95%;
  list-style: none;
}

.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: var(--button-color);
  color: var(--text-color);
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
}

.button:hover {
  background-color: var(--button-hover-color);
  transform: scale(1.015);
  color: white;
}

.left {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}

.fileName {
  font-weight: 500;
  word-break: break-word;
  text-align: left;
  flex: 1;
}

.icon {
  font-size: 1.2rem;
  flex-shrink: 0;
}

.downloadIcon {
  font-size: 1.4rem;
  margin-left: 1rem;
  flex-shrink: 0;
}

.docs_container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1rem;
  background-color: var(--card-background-color);
  border-radius: 12px;
  transition: background-color 0.3s ease;
}

.custom_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90deg, #5b95f9, #3d7bee);
  color: white;
  padding: 0.8rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.3s ease;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.custom_button:hover {
  transform: scale(1.015);
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.2);
}

.left {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.fileName {
  font-weight: 500;
  word-break: break-word;
  flex: 1;
}

.icon {
  font-size: 1.2rem;
  color: white;
  opacity: 0.7;
}

.downloadIcon {
  font-size: 1.3rem;
  margin-left: 0.5rem;
  color: white;
  opacity: 0.9;
}
