.theme_button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--themebutton-background-color);
  margin-right: 2px;
  padding: 4px;
  margin-left: 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-left: 50px;
  font-size: 30px;
  border: none;
}

.theme_button:hover {
  color: black;
  background-color: #8ca9de;
  cursor: pointer;
}

.theme_sun{
  color:yellow;
}

.theme_moon{
  color:lightsteelblue;
}