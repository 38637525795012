.card {
  width: 175px; /* largura do cartão */
  height: 310px; /* altura do cartão */
  border: 1px solid #ccc; /* borda sólida de 1px em cinza claro */
  border-radius: 10px; /* bordas arredondadas */
  background-color: #f0f0f0; /* cor de fundo do cartão */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* sombra suave */
  padding: 5px; /* espaçamento interno */
  margin: 7.5px 0; /* margem externa (vertical) */
  display: inline-block; /* torna os cartões em linha */
  vertical-align: top; /* alinha os cartões ao topo */
  text-align: center;
  
}

.cardAlign {
  height: 300px;
  display: flex; /* Alteração aqui */
  flex-direction: column; /* Adicionando isso para empilhar elementos verticalmente */
  justify-content: center; /* Centraliza verticalmente */
  align-items: center; /* Centraliza horizontalmente */

}

.cardAlign h3{
  color: #5b95f9;
  margin-bottom: 5px;
  margin-top: 10px;
}

.cardAlign p{
  color: #5b95f9;
  display: flex;
  align-items: flex-start; /* Isso faz o texto começar no topo da div */
}

.cardAlign img {
  width: 100%;
  max-height: 100%;
  margin: 0 auto; 
}

.card:hover {
  background-color: #bfd4fb;
}

/* Ajustes para alinhar os cartões lado a lado */
@media (min-width: 768px) {
  .card { 
    margin: 7.5px; /* margem externa (horizontal) */
  }

  .card:first-child {
    margin-left: 4; /* Remove a margem à esquerda do primeiro cartão */
  }

  .card:last-child {
    margin-right: 4; /* Remove a margem à direita do último cartão */
  }
}