/* docs.module.css */
.docs_background {
    list-style: none;
    padding: 1rem;
    display: flex;
    justify-content: center;
  }
  
  .docs_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 800px;
    background-color: var(--card-background-color);
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease-in-out;
  }
  
  .custom_button {
    background-color: var(--button-color);
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    width: 100%;
    text-align: left;
  }
  
  .custom_button:hover {
    background-color: var(--button-hover-color);
    transform: scale(1.01);
  }
  
  @media (max-width: 768px) {
    .custom_button {
      font-size: 0.95rem;
      padding: 0.75rem 1rem;
    }
  
    .docs_container {
      padding: 1rem;
    }
  }
  