/* Footer.module.css */

.container_light,
.container_night {
  background-color: var(--footer-background-color);
  padding: 2rem 1rem 1rem;
  border-top: 2px solid #5b95f9;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  gap: 1.5rem;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.information_container,
.social_container {
  display: flex;
  align-items: center;
  background-color: var(--footer-item-background-color);
  color: var(--footer-font-color);
  padding: 1rem;
  margin-bottom: 0.75rem;
  border-radius: 10px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  font-size: 1.1rem;
  gap: 0.75rem;
  transition: background-color 0.3s ease;
}

.information_container:hover,
.social_container:hover {
  background-color: #8ca9de;
  color: black;
}

.social_icon {
  width: 44px;
  height: 44px;
  background-color: #5b95f9;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  border-radius: 50%;
  margin-left: 0.5rem;
  transition: background-color 0.3s, color 0.3s;
}

.social_icon:hover {
  background-color: black;
  color: #aecbfe;
}

.item_icon {
  font-size: 1.4rem;
  flex-shrink: 0;
}

.list_left,
.list_right {
  flex: 1 1 300px;
}

.copyright_container {
  margin-top: 1.5rem;
  text-align: center;
  font-size: 1rem;
  color: var(--footer-font-color);
  background-color: var(--footer-item-background-color);
  padding: 0.75rem;
  border-radius: 10px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
}

.copyright_container:hover {
  cursor: default;
  color: black;
}

/* Responsivo */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: stretch;
  }

  .information_container,
  .social_container {
    flex-direction: column;
    align-items: flex-start;
  }

  .social_icon {
    margin-left: 0;
    margin-top: 0.5rem;
  }

  .item_icon {
    margin-bottom: 0.5rem;
  }
}
