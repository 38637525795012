/* ItemList.module.css */
.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.card {
  background-color: var(--card-background-color);
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  margin-bottom: 1.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
}

.expand_area {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  padding: 1rem 1.25rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--text-color);
  transition: background-color 0.2s ease;
}

.expand_area:hover {
  background-color: var(--button-hover-color);
  color: white;
}

.expand_icon {
  font-size: 1.5rem;
  flex-shrink: 0;
}

.year_label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.2rem;
  flex-grow: 1;
}

.content {
  padding: 0.5rem 1.5rem 1rem 2.25rem;
  font-size: 1rem;
  color: #555;
}

.iconColor {
  color: #5b95f9;
  margin-bottom: 0.5rem;
}

.expand_area {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  padding: 1rem 1.25rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--text-color);
  transition: background-color 0.1s ease, transform 0.1s ease;
  border-radius: 12px;
}

.expand_area:hover {
  background-color: var(--button-hover-color);
  color: white;
  transform: scale(1.015);
}

.expand_icon {
  font-size: 1.5rem;
  flex-shrink: 0;
  transition: transform 0.2s ease;
}
