/* Theme.module.css */
.light {
    /* background */
    background-color: white;
    --text-color: black;
    --background-image: url('/src/img/BackGround_light.webp');
    --background-size: cover;
    --background-position: center;
    --display: flex;
    --flex-direction: column;
    --align-items: center;
    --min-height: 100vh;
    --list-background: rgba(255, 255, 255, 0.1);
    --list-hover-background: rgba(255, 255, 255, 0.2);

    /* card */
    --card-background-color: #ffffff;
    --button-color: #5b95f9;
    --button-hover-color: #8ca9de;
    
    /* container */
    --container-background-color: #ffffff;
    --background-color: rgba(91, 149, 249, 0.95);
    --display: flex;
    --flex-direction: column;
    --align-items: center;
    --justify-content: center;
    --margin: 12em auto;
    --padding: 2.5em;
    --min-width: 75%;
    --max-width: 80%;
    --flex-grow: 1;

    /* Navbar_container */
    --navbar-background-color: white;
    --navbar-item-background-color: whitesmoke;
    --navbar-font-color: #5b95f9;
    --navbar-login-background-color: whitesmoke;
    --navbar-login-font-color: #5b95f9;

    /* card */
    --card-background-color: rgba(255, 255, 255, 0.05);
    --button-color: #5b95f9;
    --button-hover-color: #8ca9de;

    /* Footer_container */
    --footer-background-color: white;
    --footer-item-background-color: whitesmoke;
    --footer-font-color: #5b95f9;

    /* Path CSS*/
    --path-cursor-mode: default;
}


.dark {
    /* .home_background */
    background-color: #28477d;
    --text-color: white;
    --background-image: url('/src/img/BackGround_night.webp');
    --background-size: cover;
    --background-position: center;
    --display: flex;
    --flex-direction: column;
    --align-items: center;
    --min-height: 100vh;
    --list-background: rgba(255, 255, 255, 0.1);
    --list-hover-background: rgba(255, 255, 255, 0.2);
    --card-background-color: rgba(255, 255, 255, 0.05);

    
    /* container */
    --container-background-color: rgba(255, 255, 255, 0.05);
    --background-color: rgba(40, 71, 125, 0.95);
    --display: flex;
    --flex-direction: column;
    --align-items: center;
    --justify-content: center;
    --margin: 12em auto;
    --padding: 2.5em;
    --min-width: 75%;
    --max-width: 80%;
    --flex-grow: 1;

    /* Navbar_container */
    --navbar-background-color: #233c69;
    --navbar-item-background-color: steelblue;
    --navbar-font-color: white;
    --navbar-login-font-color: white;
    --navbar-login-background-color: steelblue;
    --themebutton-background-color: steelblue;

    /* Footer_container */
    --footer-background-color: #233c69;
    --footer-item-background-color: steelblue;
    --footer-font-color: white;

}