/*ExpansiveList.module.css*/
/* Container dos cards por ano */
.itemCard {
  background-color: var(--card-background-color);
  margin: 1.25rem auto;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  width: 95%;
  max-width: 800px;
}

.itemCard:hover {
  transform: scale(1.01);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

/* Botões superiores */
.buttonClass {
  margin: 0.5rem;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

.button_layout {
  background-color: var(--button-color);
}

.button_layout:hover {
  background-color: var(--button-hover-color);
  transform: scale(1.03);
}

.selected_button {
  background-color: #295aae;
}

.selected_button:hover {
  background-color: #1f4ca0;
  transform: scale(1.02);
}

/* Títulos dos blocos */
.title_docs {
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--text-color);
  margin-top: 1rem;
  margin-bottom: 0.75rem;
}

/* Animação de entrada */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.expansive_layout {
  animation: fadeIn 0.5s ease-in-out;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
