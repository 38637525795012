/*RouletteBoardPartners.module.css*/
.roulette {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 1rem;
}

.buttonLeft,
.buttonRight {
  width: 48px;
  height: 48px;
  background-color: var(--button-color);
  border: none;
  border-radius: 50%;
  font-size: 1.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.buttonLeft:hover,
.buttonRight:hover {
  background-color: var(--button-hover-color);
  transform: scale(1.1);
}

.card-board {
  display: flex;
  gap: 1rem;
  flex-wrap: nowrap;
  overflow-x: auto;
  max-width: 100%;
  padding: 0.5rem;
}

.card {
  background-color: var(--card-background-color);
  border-radius: 12px;
  padding: 1rem;
  width: 180px;
  min-width: 180px;
  text-align: center;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: scale(1.03);
}

.cargo {
  font-weight: bold;
  margin-top: 0.5rem;
}

.funcao {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.card {
  animation: slideIn 0.4s ease;
}
