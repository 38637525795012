.year_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  background-color: var(--container-background-color);
  padding: 0.9rem 1.75rem;
  border-radius: 14px;
  color: var(--text-color);
  font-size: 1.25rem;
  font-weight: 600;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  margin: 1rem auto;
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
  max-width: fit-content;
}

.year_container:hover {
  background-color: var(--button-hover-color);
  color: white;
  transform: scale(1.03);
}

.icon {
  font-size: 1.6rem;
  flex-shrink: 0;
}

.text {
  white-space: nowrap;
}
